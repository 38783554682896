:root {
  --page-gutter: 18px;
  --grid-gap: 18px;
  --page-max-width: 1248px;
  --base-font-size: 20px;
  --text-color: black;
  --page-background-color: white;
  --seperator-color: var(--text-color);
  --button-background-color: var(--page-background-color);
  --button-text-color: var(--text-color);
  --button-border-color: var(--text-color);
  --z-lowest: 0;
  --z-middle: 1;
  --z-highest: 2;
}

@media (width >= 600px) {
  :root {
    --grid-gap: 40px;
    --page-gutter: 24px;
    --base-font-size: 24px;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --text-color: white;
    --page-background-color: black;
  }
}
/*# sourceMappingURL=index.f65897f7.css.map */
